document.querySelectorAll('.eye').forEach((item) => {
  item.addEventListener('click', (e: Event) => {
    const element = e.currentTarget as HTMLElement;
    element.classList.toggle('show');
    if (element.classList.contains('show')) {
      element.parentElement.querySelector('input').setAttribute('type', 'text');
    } else {
      element.parentElement
        .querySelector('input')
        .setAttribute('type', 'password');
    }
  });
});
