import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartConfigurationCustomTypesPerDataset,
  ChartData,
  Legend,
  LinearScale,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(BarController);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);
Chart.register(Legend);

Chart.defaults.font.size = 14;
Chart.defaults.font.weight = 400;
Chart.defaults.color = '#000000';

export function chartConfig(
  data: ChartData,
  labelSettings: { display?: boolean; hideColorBox?: boolean } = {
    display: true,
    hideColorBox: false,
  },
  showHorizontal: boolean = false,
): ChartConfiguration | ChartConfigurationCustomTypesPerDataset {
  return {
    plugins: [ChartDataLabels],
    type: 'bar',
    data,
    options: {
      animation: {
        duration: 0,
      },
      indexAxis: showHorizontal ? 'y' : 'x',
      scales: {
        x: {
          beginAtZero: true,
          max: 6,
          min: 0,
          ticks: { stepSize: 1 },
        },
        y: {
          beginAtZero: true,
          grace: '10%',
          max: 8,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      normalized: true,
      devicePixelRatio: 4,
      plugins: {
        legend: {
          display: labelSettings.display,
          labels: {
            boxWidth: labelSettings.hideColorBox ? 0 : 40,
            padding: 20,
          },
        },
      },
    },
  };
}

const OVERALL_AVERAGE = 'Overall Average';

const overAllScoresChart: ChartData = {
  labels: window.chartData.overallScore.map(
    (categoryScore) => categoryScore.category,
  ),
  datasets: [
    {
      label: 'OVERALL RESULTS',
      data: window.chartData.overallScore.map(
        (categoryScore) => categoryScore.score,
      ),
      backgroundColor: window.chartData.overallScore.map(
        (categoryScore) => categoryScore.color,
      ),

      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const leadScoresChart: ChartData = {
  labels: window.chartData.leadScore.map(
    (categoryScore) => categoryScore.category,
  ),
  datasets: [
    {
      label: OVERALL_AVERAGE,
      data: window.chartData.leadScore.map(
        (subCategoryScore) => subCategoryScore.score,
      ),
      backgroundColor: window.chartData.leadScore[0].color,
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
    {
      label: 'Self',
      data: window.chartData.leadScore.map(
        (subCategoryScore) => subCategoryScore.selfScore,
      ),
      backgroundColor: window.chartData.leadScore[0].color + '94',
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const leadScoresChartByRaterGroups: ChartData = {
  labels: [
    'Overall',
    ...window.chartData.leadScore[0].raterTypeScore.map(
      (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.raterType,
    ),
    'Self',
  ],
  datasets: [
    {
      data: [
        window.chartData.leadScore[0].score,
        ...window.chartData.leadScore[0].raterTypeScore.map(
          (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.score,
        ),
        window.chartData.leadScore[0].selfScore,
      ],
      backgroundColor: [
        window.chartData.leadScore[0].color,
        ...window.chartData.leadScore[0].raterTypeScore.map(
          () => window.chartData.leadScore[0].color,
        ),
        window.chartData.leadScore[0].color + '98',
      ],
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const developScoresChart: ChartData = {
  labels: window.chartData.developScore.map(
    (subCategoryScore) => subCategoryScore.category,
  ),
  datasets: [
    {
      label: OVERALL_AVERAGE,
      data: window.chartData.developScore.map(
        (subCategoryScore) => subCategoryScore.score,
      ),
      backgroundColor: window.chartData.developScore[0].color,
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
    {
      label: 'Self',
      data: window.chartData.developScore.map(
        (subCategoryScore) => subCategoryScore.selfScore,
      ),
      backgroundColor: window.chartData.developScore[0].color + '94',
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const developScoresChartByRaterGroups: ChartData = {
  labels: [
    'Overall',
    ...window.chartData.developScore[0].raterTypeScore.map(
      (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.raterType,
    ),
    'Self',
  ],
  datasets: [
    {
      data: [
        window.chartData.developScore[0].score,
        ...window.chartData.developScore[0].raterTypeScore.map(
          (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.score,
        ),
        window.chartData.developScore[0].selfScore,
      ],
      backgroundColor: [
        window.chartData.developScore[0].color,
        ...window.chartData.developScore[0].raterTypeScore.map(
          () => window.chartData.developScore[0].color,
        ),
        window.chartData.developScore[0].color + '98',
      ],
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const careScoresChart: ChartData = {
  labels: window.chartData.careScore.map(
    (subCategoryScore) => subCategoryScore.category,
  ),
  datasets: [
    {
      label: OVERALL_AVERAGE,
      data: window.chartData.careScore.map(
        (subCategoryScore) => subCategoryScore.score,
      ),
      backgroundColor: window.chartData.careScore[0].color,
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
    {
      label: 'Self',
      data: window.chartData.careScore.map(
        (subCategoryScore) => subCategoryScore.selfScore,
      ),
      backgroundColor: window.chartData.careScore[0].color + '94',
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const careScoresChartByRaterGroups: ChartData = {
  labels: [
    'Overall',
    ...window.chartData.developScore[0].raterTypeScore.map(
      (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.raterType,
    ),
    'Self',
  ],
  datasets: [
    {
      data: [
        window.chartData.careScore[0].score,
        ...window.chartData.careScore[0].raterTypeScore.map(
          (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.score,
        ),
        window.chartData.careScore[0].selfScore,
      ],
      backgroundColor: [
        window.chartData.careScore[0].color,
        ...window.chartData.careScore[0].raterTypeScore.map(
          () => window.chartData.careScore[0].color,
        ),
        window.chartData.careScore[0].color + '98',
      ],
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const eqSelfScoresChart: ChartData = {
  labels: window.chartData.eqScore.eq_self.map(
    (subCategoryScore) => subCategoryScore.category,
  ),

  datasets: [
    {
      label: OVERALL_AVERAGE,
      data: window.chartData.eqScore.eq_self.map(
        (subCategoryScore) => subCategoryScore.score,
      ),

      backgroundColor: window.chartData.eqScore.eq_self[0].color,
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
    {
      label: 'Self',
      data: window.chartData.eqScore.eq_self.map(
        (subCategoryScore) => subCategoryScore.selfScore,
      ),
      backgroundColor: window.chartData.eqScore.eq_self[0].color + '94',
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const eqOthersScoresChart: ChartData = {
  labels: window.chartData.eqScore.eq_others.map(
    (subCategoryScore) => subCategoryScore.category,
  ),

  datasets: [
    {
      label: OVERALL_AVERAGE,
      data: window.chartData.eqScore.eq_others.map(
        (subCategoryScore) => subCategoryScore.score,
      ),

      backgroundColor: window.chartData.eqScore.eq_others[0].color,
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
    {
      label: 'Self',
      data: window.chartData.eqScore.eq_others.map(
        (subCategoryScore) => subCategoryScore.selfScore,
      ),
      backgroundColor: window.chartData.eqScore.eq_others[0].color + '94',
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};
const eqSelfScoresChartByRaterGroups: ChartData = {
  labels: [
    'Overall',
    ...window.chartData.eqScore.eq_self[0].raterTypeScore.map(
      (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.raterType,
    ),
    'Self',
  ],
  datasets: [
    {
      data: [
        window.chartData.eqScore.eq_self[0].score,
        ...window.chartData.eqScore.eq_self[0].raterTypeScore.map(
          (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.score,
        ),
        window.chartData.eqScore.eq_self[0].selfScore,
      ],
      backgroundColor: [
        window.chartData.eqScore.eq_self[0].color,
        ...window.chartData.eqScore.eq_self[0].raterTypeScore.map(
          () => window.chartData.eqScore.eq_self[0].color,
        ),
        window.chartData.eqScore.eq_self[0].color + '98',
      ],
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

const eqOthersScoresChartByRaterGroups: ChartData = {
  labels: [
    'Overall',
    ...window.chartData.eqScore.eq_others[0].raterTypeScore.map(
      (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.raterType,
    ),
    'Self',
  ],
  datasets: [
    {
      data: [
        window.chartData.eqScore.eq_others[0].score,
        ...window.chartData.eqScore.eq_others[0].raterTypeScore.map(
          (subCategoryRaterTypeScore) => subCategoryRaterTypeScore.score,
        ),
        window.chartData.eqScore.eq_others[0].selfScore,
      ],
      backgroundColor: [
        window.chartData.eqScore.eq_others[0].color,
        ...window.chartData.eqScore.eq_others[0].raterTypeScore.map(
          () => window.chartData.eqScore.eq_others[0].color,
        ),
        window.chartData.eqScore.eq_others[0].color + '98',
      ],
      datalabels: {
        color: 'black',
        anchor: 'start',
        align: 'end',
      },
    },
  ],
};

function createChart(
  element: CanvasRenderingContext2D,
  dataConfig: ChartConfiguration | ChartConfigurationCustomTypesPerDataset,
) {
  new Chart(element, dataConfig);
}

createChart(
  (document.getElementById(`lead-results`) as HTMLCanvasElement)?.getContext(
    '2d',
  ),
  chartConfig(leadScoresChart, { display: true }),
);

createChart(
  (
    document.getElementById(`lead-results-individual`) as HTMLCanvasElement
  )?.getContext('2d'),
  chartConfig(leadScoresChartByRaterGroups, { display: false }, true),
);

createChart(
  (document.getElementById(`develop-results`) as HTMLCanvasElement)?.getContext(
    '2d',
  ),
  chartConfig(developScoresChart, { display: true }),
);

createChart(
  (
    document.getElementById(`develop-results-individual`) as HTMLCanvasElement
  )?.getContext('2d'),
  chartConfig(developScoresChartByRaterGroups, { display: false }, true),
);

createChart(
  (document.getElementById(`care-results`) as HTMLCanvasElement)?.getContext(
    '2d',
  ),
  chartConfig(careScoresChart, { display: true }),
);

createChart(
  (
    document.getElementById(`care-results-individual`) as HTMLCanvasElement
  )?.getContext('2d'),
  chartConfig(careScoresChartByRaterGroups, { display: false }, true),
);

createChart(
  (document.getElementById(`eq-self`) as HTMLCanvasElement)?.getContext('2d'),
  chartConfig(eqSelfScoresChart),
);
createChart(
  (document.getElementById(`eq-others`) as HTMLCanvasElement)?.getContext('2d'),
  chartConfig(eqOthersScoresChart),
);

createChart(
  (
    document.getElementById(`eq-self-results-individual`) as HTMLCanvasElement
  )?.getContext('2d'),
  chartConfig(eqSelfScoresChartByRaterGroups, { display: false }, true),
);

createChart(
  (
    document.getElementById(`eq-others-results-individual`) as HTMLCanvasElement
  )?.getContext('2d'),
  chartConfig(eqOthersScoresChartByRaterGroups, { display: false }, true),
);

createChart(
  (document.getElementById(`overall-rating`) as HTMLCanvasElement)?.getContext(
    '2d',
  ),
  chartConfig(overAllScoresChart, { display: true, hideColorBox: true }),
);
